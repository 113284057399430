<template>
  <article id="wrap">

    <AppHeader theme="yellow">パスワードを忘れた方</AppHeader>

    <section class="contents">

      <div class="contentInner">

        <div class="white_bg_area2">

          <div class="mb_20">ご入力いただいたメールアドレス宛にパスワード再設定についてのメールをお送りしましたのでご確認ください。</div>

          <div class="mb_20 text_gray">※パスワード再設定メールに記載されたURLの有効期限は1時間です。</div>

        </div><!-- .white_bg_area -->

      </div><!--.contentInner-->

    </section><!--.contents-->

    <AppFooter theme="yellow" fixed></AppFooter>

  </article>
</template>

<script>
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
export default {
  name: 'PasswordSend',
  components: {AppHeader, AppFooter}
}
</script>
